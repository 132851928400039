@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 400px;
  width: 100%;
  padding: 60px 0;

  .title {
    position: relative;
    z-index: 2;
    color: #1A1A1A;
    // font-weight: 400;
    // font-size: 30px;
    font-family: $primary_font;
    margin-bottom: 0;
    line-height: 140%;
    // line-height: 48px;

    font-style: normal;

    font-weight: 300;
    font-size: 40px;

    align-self: stretch;
    flex-grow: 0;
    margin: 16px 0px;

    // @include responsive-down(md) {
    //   display: block;
    // }

    b {
      font-weight: 500;
    }

    span {
      color: $primary_color;
    }
    @include responsive-down(md) {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      // display: flex;
      align-items: center;
      text-align: center;
    }
  }
  .text {
    position: relative;
    z-index: 2;
    color: #000000;
    margin-bottom: 0;
    margin-top: 15px;

    /* Large Text / Regular */

    font-family: $primary_color;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;

    letter-spacing: 0.02em;

    // flex: none;
    // order: 1;
    // align-self: stretch;
    // flex-grow: 0;
    margin: 16px 0px;

    @include responsive-down(md) {
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }

  .btns {
    // display: flex;
    // flex-direction: row;
    // justify-content: flex-start;
    // align-items: flex-start;
    // flex-wrap: wrap;
    width: 100%;
    margin-top: 40px;

    .btn__clr {
      margin: 0 16px 0 0 !important;
      &:hover {
        background-color: #7557BB;
        color: white;
      }
    }
  }
}

// .home__hero {
//   background: transparent;
//   border-bottom: none;
// }
// .home__content__center {
//   align-items: center;
// }
// .home__content__center .home__title {
//   text-align: center;
// }
// .home__content__center .home__text {
//   text-align: center;
// }
// .home__content__center .home__btns {
//   justify-content: center;
// }

@media screens and (min-width: 768px) {
  .content {
    padding: 70px 0;
    .title {
      font-size: 36px;
    }
    .btn {
      margin-top: 30px;
      margin-right: 30px;
      min-width: 160px;
      padding: 0 25px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
@media screens and (min-width: 1200px) {
  .title {
    font-size: 42px;
  }
  .btn {
    margin-top: 40px;
  }
}
